import React from 'react';

export const PortfolioItem = () => {
	return (
		<div className="col-sm-4">
			<div className="card">
				<img
					className="card-img-top"
					src="https://i.picsum.photos/id/866/200/200.jpg"
					alt="portfolio item"
				/>
				<div className="card-body">
					<h5 className="card-title">Name</h5>
					<p className="card-text">Description</p>
				</div>
			</div>
		</div>
	);
};
