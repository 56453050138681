import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import './benefit-item.scss';

interface Props {
	icon?: JSX.Element;
	title: string;
	description: string;
}

export const BenefitItem: React.FunctionComponent<Props> = ({
	icon,
	title,
	description
}) => {
	return (
		<div className="col-lg-4">
			{icon}
			<div className="benefit-item-placeholder" />
			<h2 className="my-3">{title}</h2>
			<p>{description}</p>
		</div>
	);
};
