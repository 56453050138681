import React from 'react';

import { PortfolioItem } from './portfolio-item';

import './portfolio.scss';

const ITEMS = [1, 2, 3, 4, 5];

export const Portfolio = () => {
	return (
		<section className="portfolio">
			<div className="container py-5">
				<div className="d-flex justify-content-center p-5">
					<h2 className="portfolio-title">Portfolio</h2>
				</div>
				<div className="row">
					{ITEMS.map(item => (
						<PortfolioItem key={item} />
					))}
				</div>
			</div>
		</section>
	);
};
