import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-scroll';

// import { Link } from 'react-router-dom';

import './navbar.scss';

interface State {
	show: boolean;
	isTop: boolean;
}

export class Navbar extends React.Component<{}, State> {
	constructor(props: {}) {
		super(props);

		this.state = {
			show: false,
			isTop: true
		};
	}

	toggleNavbar = () => {
		this.setState({
			show: !this.state.show
		});
	};

	componentDidMount() {
		document.addEventListener('scroll', () => {
			const isTop = window.scrollY < 54;
			if (isTop !== this.state.isTop) {
				this.setState({ isTop });
			}
		});
	}

	render() {
		const { show } = this.state;

		return (
			<div className="navbar navbar-expand-lg fixed-top navbar-dark navbar-primary">
				<div className="container">
					<Link
						className="navbar-brand"
						to="home"
						spy={true}
						smooth={true}
						offset={-70}
						duration={500}
						onClick={this.toggleNavbar}
					>
						Name
					</Link>
					<button
						className="navbar-toggler"
						type="button"
						aria-label="Toggle navigation"
						onClick={this.toggleNavbar}
					>
						<span className="navbar-toggler-icon" />
					</button>

					<div
						className={classNames('collapse navbar-collapse', {
							show
						})}
						id="navbarColor01"
					>
						<ul className="navbar-nav ml-auto">
							<li className="nav-item" onClick={this.toggleNavbar}>
								<Link
									className="nav-link"
									to="benefits"
									spy={true}
									smooth={true}
									offset={-70}
									duration={500}
									onClick={this.toggleNavbar}
								>
									Benefits
								</Link>
							</li>
							<li className="nav-item" onClick={this.toggleNavbar}>
								<Link
									className="nav-link"
									to="features"
									spy={true}
									smooth={true}
									offset={-70}
									duration={500}
									onClick={this.toggleNavbar}
								>
									Features
								</Link>
							</li>
							<li className="nav-item" onClick={this.toggleNavbar}>
								<Link
									className="nav-link"
									to="portfolio"
									spy={true}
									smooth={true}
									offset={-70}
									duration={500}
									onClick={this.toggleNavbar}
								>
									Portfolio
								</Link>
							</li>
							<li className="nav-item" onClick={this.toggleNavbar}>
								<Link
									className="nav-link"
									to="join"
									spy={true}
									smooth={true}
									offset={-70}
									duration={500}
									onClick={this.toggleNavbar}
								>
									Join
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		);
	}
}
