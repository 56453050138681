import React from 'react';

import './join.scss';

export const Join = () => {
	return (
		<section id="join">
			<div className="container">
				<div className="row">
					<div className="col-md-12 text-center p-5">
						<h3 className="join-title">Call to action</h3>
						<button type="button" className="btn btn-primary">
							Join
						</button>
					</div>
				</div>
			</div>
		</section>
	);
};
