/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import './home.scss';

export const Home = () => {
	return (
		<section className="home" id="home">
			<div className="background">
				<div className="jumbotron text-center">
					<div className="container">
						<h1 className="jumbotron-heading">Awesome Name</h1>
						<p className="lead text-description">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
							enim ad minim veniam, quis nostrud exercitation ullamco.
						</p>
						<p>
							<a href="#" className="btn btn-primary btn-lg m-2">
								Join
							</a>
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};
