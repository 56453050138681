import React from 'react';

import { Home } from './home/home';
import { Benefits } from './benefits/benefits';
import { Features } from './features/features';
import { Portfolio } from './portfolio/portfolio';
import { Join } from './join/join';

import './main.scss';

export const Main = () => {
	return (
		<>
			<Home />
			<main role="main">
				<Benefits />
				<Features />
				<Portfolio />
				<Join />
			</main>
		</>
	);
};
