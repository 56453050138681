import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { ROUTES } from './core/constants';
import { Navbar } from './features/common/navbar';
import { Footer } from './features/common/footer';
import { Main } from './features/main';

export const Root = () => (
	<BrowserRouter>
		<Navbar />
		<Switch>
			<Route exact path={ROUTES.HOME} component={Main} />
		</Switch>
		<Footer />
	</BrowserRouter>
);
