import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faUserCheck,
	faRocket,
	faLifeRing
} from '@fortawesome/free-solid-svg-icons';

import { BenefitItem } from './benefit-item';

import './benefits.scss';

export const Benefits = () => {
	const BENEFITS = [
		{
			icon: <FontAwesomeIcon size="4x" icon={faUserCheck} />,
			title: 'First benefit',
			description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
			sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`
		},
		{
			icon: <FontAwesomeIcon size="4x" icon={faRocket} />,
			title: 'Second benefit',
			description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
			sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`
		},
		{
			icon: <FontAwesomeIcon size="4x" icon={faLifeRing} />,
			title: 'Third benefit',
			description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
			sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`
		}
	];

	return (
		<section id="benefits">
			<div className="benefits">
				<div className="container benefits-container">
					<div className="row">
						{BENEFITS.map(benefit => (
							<BenefitItem
								key={benefit.title}
								icon={benefit.icon}
								title={benefit.title}
								description={benefit.description}
							/>
						))}
					</div>
				</div>
			</div>
		</section>
	);
};
