import React from 'react';

import { FeatureItem } from './feature-item';

import './features.scss';

const FEATURES = [
	{
		title: 'Feature 1',
		description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
		sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
		Ut enim ad minim veniam.`,
		img: {
			src: 'https://picsum.photos/400',
			alt: 'hands'
		}
	},
	{
		title: 'Feature 2',
		description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
		sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
		Ut enim ad minim veniam.`,
		img: {
			src: 'https://picsum.photos/500',
			alt: 'roadmap'
		}
	}
];

export const Features = () => {
	return (
		<section className="features bg-light">
			<div className="container">
				<div className="d-flex justify-content-center p-5">
					<h2>Technology working for you</h2>
				</div>
				{FEATURES.map(feature => (
					<FeatureItem key={feature.title} feature={feature} />
				))}
			</div>
		</section>
	);
};
